<template>
  <div>
    <c-table
      ref="table"
      :title="`단위작업 : ${hazard.sopName}`"
      :columns="grid.columns"
      :data="hazard.results"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="heaMuscleSopResultId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled" 
            label="추가" 
            icon="add"
            @btnClicked="addResult" />
          <c-btn 
            v-show="editable&&!disabled && hazard.results.length>0" 
            label="삭제" 
            icon="remove" 
            @btnClicked="deleteResult" />
          <c-btn 
            v-show="editable && !disabled && hazard.results.length>0" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="hazard.results"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveResult"
            @btnCallback="saveResultCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="heaMuscleSopResultId"
            ibmTaskTypeCd="ITT0000170"
            ibmTaskUnderTypeCd="ITTU000190"
            @imprChange="imprChange"
            @getImprList="getImprList"
          />
        </template>
      </template>
    </c-table>
    <c-card class="cardClassDetailForm" title="" noHeader>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-upload 
            :attachInfo="attachResultInfo"
            :editable="editable"
            label="작업사진">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'muscleHazardCheck',
  props: {
    hazard: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSopStepCd: '',  // 진행상태
        processCd: '',  // 공정코드
        processName: '',  // 공정명
        mdmSopId: '',  // 안전작업 표준 일련번호
        sopName: '',  // 세부공정(작업) 명
        heaInvestigationTypeCd: '',  // 조사구분
        investigationDate: '',  // 조사일시
        deptCd: '',  // 조사자 부서
        userId: '',  // 조사자 id
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        heavyWorks: [], // 부담작업
        heavyWorkIncludes: [], // 부담작업 결과 O
        heavyWorkHazards: [], // 유해요인
        workers: [], // 작업자
        situations: [], // 상황조사
        results: [], // 결과표
        imprs: [], // 개선
      }),
    },
    attachResultInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'HEA_MUSCLE_HAZARD_RESULT',
        taskKey: '',
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'heaAssessmentToolCd',
            field: 'heaAssessmentToolCd',
            label: '작업분석․평가도구',
            align: 'center',
            style: 'width:25%',
            type: 'select',
            innerBtn: true,
            codeGroupCd: 'HEA_ASSESSMENT_TOOL_CD',
            sortable: false,
          },
          {
            required: true,
            name: 'resultName',
            field: 'resultName',
            label: '평가결과',
            align: 'left',
            style: 'width:25%',
            type: 'textarea',
            sortable: false,
          },
          {
            required: true,
            name: 'judgment',
            field: 'judgment',
            label: '판정',
            align: 'left',
            style: 'width:20%',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'workerOpinion',
            field: 'workerOpinion',
            label: '근로자의견',
            align: 'left',
            style: 'width:10%',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선번호/개선진행상태',
            align: 'center',
            style: 'width:20%',
            type: 'custom',
            sortable: false
          },
        ],
      },
      editable: true,
      isSave: true,
      listUrl: '',
      listImprUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 350) + 'px' : '500px'
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['resultName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSopResult.list.url
      this.listImprUrl = selectConfig.hea.muscleSystem.muscleSopResultImpr.list.url
      this.saveUrl = transactionConfig.hea.muscleSystem.muscleSopResult.save.url
      this.deleteUrl = transactionConfig.hea.muscleSystem.muscleSopResult.delete.url
      // code setting
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaMuscleSopId: this.popupParam.heaMuscleSopId
      };
      this.$http.request((_result) => {
        this.$set(this.hazard, 'results', _result.data);
      },);
    },
    getImprList() {
      this.$http.url = this.listImprUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaMuscleSopId: this.popupParam.heaMuscleSopId
      };
      this.$http.request((_result) => {
        this.$set(this.hazard, 'imprs', _result.data);
      },);
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.$emit('getDetail', 'impr');
    },
    addResult() {
      this.hazard.results.splice(0, 0, {
        heaMuscleSopResultId: uid(),  // 근골격계 단위작업 결과표 일련번호
        heaMuscleSopId: this.popupParam.heaMuscleSopId,  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: this.hazard.heaInvestigationPlanId,  // 근골격계 조사계획 일련번호
        heaAssessmentToolCd: null,  // 작업분석 평가도구 코드
        resultName: '',  // 평가결과
        judgment: '',  // 유해요인의 발생원인 또는 노출특징
        workerOpinion: '',  // 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    deleteResult() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.hazard.results = this.$_.reject(this.hazard.results, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveResult() {      
      if (this.$comm.validTable(this.grid.columns, this.hazard.results)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveResultCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>